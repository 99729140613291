<template>
  <div class="collect-fee">    
      <v-card class="mx-4 my-4 px-4 py-4">
        <h2 class="text-left mb-2">Transaction Details</h2>
        <v-divider class="mb-6"></v-divider>
        <v-row>
          <v-col cols="12" md="6" class="py-0">
            <p class="text-left">
              Student Name :
              <b>{{ feeCollectionData.profile.user.full_name }}</b>
            </p>
            <p class="text-left">
              Roll number :
              <b>{{ feeCollectionData.profile.user.roll_number }}</b>
            </p>
            <p class="text-left">
              Total Payable :
              <b>{{ feeCollectionData.is_custom ?  Helper.currencyFormat(Helper.totalSum(feeCollectionData.instalments, 'paying')) : Helper.currencyFormat(Helper.totalSum(feeCollectionData.schedule_instalment.instalments, 'paying')) }}</b>
            </p>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <p class="text-left">
              Payment Date :
              <b>{{ moment(feeCollectionData.payment_date).format('DD MMM YYYY') }}</b>
            </p>
          </v-col>
        </v-row>
      </v-card>

      <v-btn v-if="goBackOrCancel" color="red" class="white--text mx-2 my-4" @click="cancelFeeCollection">Cancel</v-btn>
      <v-btn v-else color="primary" class="white--text mx-2 my-4" @click="goBack">Go Back</v-btn>
      <v-btn color="primary" class="white--text mx-2 my-4" @click="makePayment" :disabled="disablePayFees">Pay Now</v-btn>
      <v-btn color="primary" class="white--text mx-2 my-4" :disabled="disablePrint" @click="printReceipt">Print Receipt</v-btn>

         <v-snackbar v-model="snackbar.visible">
          {{ snackbar.message }}
          <template>
            <v-btn color="pink" text @click="snackbar.visible = false">Close</v-btn>
          </template>
        </v-snackbar>
     
  </div>
</template>
<script>
import Helper from "@utils/misc";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import api from "@api/index";
import endpoints from "@api/repository";
// import axios from 'axios';
import RazorpayLibrary from 'razorpay'

export default {
  name: "CollectFee",
  props: ["total_payable"],
  data() {
    return {
      disablePrint: true,
      goBackOrCancel: true,
      disablePayFees: false,
      responseObject: null,
      paymentDetails: null,
      moment: moment,
      Helper: Helper,
      rules: {
        required: (value) => !!value || "Required.",
      },
      snackbar: {
        visible: false,
        message: "",
      }
    };
  },
  computed: {
    ...mapGetters([
      "feeCollectionData",
      "accessToken",
      "currentUser",
      "currentInstitution",
      "isLoading",
      "activeFeeSession",
    ]),
    essentials() {
      return {
        accessToken: this.accessToken,
        setLoading: this.setLoading,
        handleErrorsFunction: this.handleApiErrors,
      };
    },
  },
  methods: {
    ...mapActions(["setLoading","clearFeeCollectionData"]),

    makePayment(){
      let amount = this.feeCollectionData.is_custom ?  Helper.totalSum(this.feeCollectionData.instalments, 'paying') : Helper.totalSum(this.feeCollectionData.schedule_instalment.instalments, 'paying')

      var instance = new RazorpayLibrary({
        key_id: process.env.VUE_APP_RAZORPAY_KEY_ID,
        key_secret: process.env.VUE_APP_RAZORPAY_KEY_SECRET
      })
      let order_id = null
      let self = this
      instance.orders.create({amount: amount * 100})
      .then((data) => {
        var options = {
          "key_id": process.env.VUE_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
          "key_secret": process.env.VUE_APP_RAZORPAY_KEY_SECRET,
          "amount": amount * 100 , // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          "currency": "INR",
          "name": "Fees Payment",
          "description": "Test Transaction",
          "order_id": data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          "handler": function (response){
              self.disablePrint = false;
              self.disablePayFees = true;
              self.goBackOrCancel = false;
              self.snackbar.message = "Transaction Successful !";
              self.snackbar.visible = true;
              self.paymentDetails = response;
              self.savePaymentDetails(data.id);
              // self.$router.push({ name: 'FeeDashboard' })
          },
          "prefill": {
              "name": this.feeCollectionData.profile.user.full_name,
              "contact": this.feeCollectionData.profile.user.phone,
          },
          "notes": {
              "address": "Razorpay Corporate Office"
          },
          "theme": {
              "color": "#3399cc"
          }
        }
        // eslint-disable-next-line 
        var rzp1 = new Razorpay(options);
        rzp1.on('payment.failed', function (response){
                alert(response.error.code);
                alert(response.error.description);
                alert(response.error.source);
                alert(response.error.step);
                alert(response.error.reason);
                alert(response.error.metadata.order_id);
                alert(response.error.metadata.payment_id);
        });
        rzp1.open()
      })
      .catch(console.error)
      instance.orders.fetchPayments(order_id).then(console.log).catch(console.error)
    },

    printReceipt(){
      this.$router.push({ name: "ViewFeesPaid", params: {sid: this.feeCollectionData.profile.user.id, rid: this.responseObject.id}});
      console.log(this.paymentDetails);
      this.clearFeeCollectionData();
    },

    formattedDate(date) {
      if (date == null) return "";
      return moment(date).format("DD-MM-YYYY");
    },

    async cancelFeeCollection (){ 
      // var student_id = this.feeCollectionData.profile.user.id;
      this.clearFeeCollectionData();
      this.$router.push({ name: 'FeeDashboard'});
    },

    async goBack (){
      this.$router.push({name: 'FeeDashboard'});
    },

    async savePaymentDetails(order_id) {
      var body = {
        fee_collection_data: this.feeCollectionData,
        payment_details: this.paymentDetails,
        order_id,
        session: this.activeFeeSession.id,
      };
      var response = await api.call(
        this.essentials,
        endpoints.saveOnlinePaymentDetails,
        api.Methods.POST,
        body
      );
      if (response) {
        console.log(response);
        this.responseObject = response;
        // this.$router.push({ name: "ViewFeesPaid", params: {sid: this.feeCollectionData.profile.user.id, rid: response.id}});
        // this.clearFeeCollectionData();
      }
    
    },


    async handleApiErrors(err) {
      this.setLoading(false);
      console.log(err);
      if (err.response) {
        if (err.response.status == 409) {
          this.snackbar.text =
            "Receipt number is already used. Please use a different number";
          this.snackbar.visible = true;
        }
      } else {
        this.snackbar.message = "Unable to process request";
        this.snackbar.visible = true;
      }
    },
  },
  created() {
    if(!this.feeCollectionData){
      this.$router.push({ name: "FeeDashboard" });
      return;
    }
    if (!this.feeCollectionData.profile) {
      // end of flow.
      this.$router.push({ name: "FeeDashboard" });
      return;
    }
    console.log(this.feeCollectionData);
  },
};
</script>

<style scoped>
.rounded-input {
  border-radius: 12px !important;
}
.payment-active {
  background-color: aqua;
  color: white;
}

.v-expansion-panel-header--active{
  margin-bottom: 10px;
}
</style>